<template>
  <div class="home">
    <div v-if="!pdf" class="relative bg-white shadow-bottom z-0">
      <div class="mx-auto px-6">
        <div class="flex justify-start items-center pb-8 pt-8 space-x-10">
          <div class="justify-start w-0 flex-1 flex my-4" v-if="currentFolder === ''"></div>
          <div class="justify-start w-0 flex-1 flex" v-if="currentFolder != ''">
            <div class="flex" v-for="(folder, index) in currentFolder.split('/')" :key="index">
              <div class="cursor-pointer px-4 py-1 rounded-sm font-bold" :class="[index < currentFolder.split('/').length - 1 ? 'bg-light' : 'bg-primary text-white']" @click="changeFolder(currentFolder.split('/').splice(0, index + 1).join('/'))">
                {{ folder.split('/')[folder.split('/').length - 1] }}
              </div>
              <span class="cursor-default mx-2 text-2xl -mt-1" v-if="index < currentFolder.split('/').length - 1">/</span>
            </div>
          </div>
          <div class="flex justify-end">
            <font-awesome-icon icon="sync-alt" class="cursor-pointer" @click="refresh()"/>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!file" class="ml-8">
      <div class="flex justify-start items-center pt-6 space-x-10 mr-10">
        <div class="flex items-center justify-end flex-1 w-0">
          <div class="pt-2 relative">
            <input class="bg-white shadow h-10 pl-10 pr-4 w-96 rounded-lg text-sm focus:outline-none placeholder-black"
              type="search" name="search" placeholder="Zoek je iets?" v-model="search" @keyup="searchFolder()" @search="searchFolder()">
            <font-awesome-icon icon="search" class="absolute left-0 top-0 mt-5 ml-4 cursor-pointer text-primary" @click="searchFolder()"/>
          </div>
        </div>
      </div>
      <div class="my-4 font-medium flex">
        <p class="flex-none mr-3">Mappen ({{ directories.length }})</p>
        <hr class="w-full flex-1 mt-3">
      </div>
      <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 mr-4">
        <div v-for="directory in directories" :key="directory" class="flex-col flex justify-content bg-light rounded-xl cursor-pointer" @click="newFolder(directory.split('/')[directory.split('/').length - 1])">
          <img src="@/assets/images/np_folder.svg" class="px-4 pt-4 -mb-1 mx-auto" alt="">
          <p class="mx-auto mb-1 break-all text-center px-3">{{ directory.split('/')[directory.split('/').length - 1] }}</p>
          <p class="mx-auto mb-3 text-sm text-gray-400">{{ nextFolderItems(directory) }} items</p>
        </div>
      </div>
      <div class="my-4 font-medium flex">
        <p class="flex-none mr-3">Documenten ({{ files.length }})</p>
        <hr class="w-full flex-1 mt-3">
      </div>
      <div class="grid grid-cols-2 gap-4 ml-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 mb-10">
        <div v-for="file in files" :key="file" class="mt-4 flex-col flex justify-content mr-4">
          <router-link v-if="search === ''" :to="'/home/' + file.split('/')[file.split('/').length - 1] + '?path=' + currentFolder">
            <img :src="extension(file)" class="w-20 mx-auto cursor-pointer" alt="">
            <p class="mx-auto cursor-pointer break-all text-center">{{ file.split('/')[file.split('/').length - 1] }}</p>
          </router-link>
          <router-link v-if="search !== ''" :to="'/home/' + file.split('/')[file.split('/').length - 1] + '?path=' + file.split('/').splice(0, file.split('/').length - 1).join('/')">
            <img :src="extension(file)" class="w-20 mx-auto cursor-pointer" alt="">
            <p class="mx-auto cursor-pointer break-all text-center">{{ file.split('/')[file.split('/').length - 1] }}</p>
          </router-link>
        </div>
      </div>
    </div>
    <div v-if="file && !pdf">
      <div class="mx-auto w-72 my-20 bg-light rounded-xl p-8">
        <div class="flex justify-end">
          <font-awesome-icon icon="times" size="lg" class="-mt-2 -mr-2 cursor-pointer" @click="close()"/>
        </div>
        <img :src="extension(file)" class="w-20 mx-auto" alt="">
        <p class="mx-auto text-center mt-2">{{ file.split('/')[file.split('/').length - 1] }}</p>
        <button v-if="file.split('.')[file.split('.').length - 1].toLowerCase() === 'pdf'" class="mx-auto text-center w-full mt-3 bg-primary font-bold text-white rounded-lg h-8" @click="downloadFile(file)">Openen</button>
        <button v-if="file.split('.')[file.split('.').length - 1].toLowerCase() !== 'pdf'" class="mx-auto text-center w-full mt-3 bg-primary font-bold text-white rounded-lg h-8" @click="downloadFile(file)">Downloaden</button>
        <div class="flex items-center mx-auto w-full mt-3 bg-secondary rounded-lg h-8">
          <input class="h-8 pl-3 w-16 flex flex-1 text-white rounded-l-lg" type="text" disabled :value="getUrl()">
          <font-awesome-icon icon="copy" class="mx-2 w-8 cursor-pointer text-white" @click="copyLink()"/>
        </div>
        <p v-if="copied" class="mx-auto text-center">Gekopieerd!</p>
      </div>
    </div>
    <div v-if="file && pdf" class="h-screen w-screen md:-ml-72">
      <div class="bg-light pt-2 h-full flex flex-col">
        <div class="flex justify-end">
          <div class="flex items-center bg-secondary rounded-lg cursor-pointer mb-2 mr-2" @click="download(file)">
            <input class="h-8 pl-3 w-32 flex flex-1 text-white rounded-l-lg cursor-pointer text-center" type="text" disabled value="Downloaden">
            <font-awesome-icon icon="download" size="sm" class="mx-2 cursor-pointer text-white"/>
          </div>
          <div class="flex items-center bg-primary rounded-lg cursor-pointer mb-2 mr-2" @click="closePdf(file)">
            <input class="h-8 pl-3 w-32 flex flex-1 text-white rounded-l-lg cursor-pointer text-center" type="text" disabled value="Sluiten">
            <font-awesome-icon icon="times" size="sm" class="mx-2 cursor-pointer text-white"/>
          </div>
        </div>
        <iframe :src="pdf" class="w-full h-full"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { FileService } from '../services/file.service.js'

export default {
  title: 'Home',
  name: 'Home',
  data () {
    return {
      currentFolder: '',
      directories: [],
      files: [],
      error: null,
      search: '',
      file: null,
      copied: false,
      pdf: null
    }
  },
  mounted () {
    this.checkState()
  },
  watch: {
    $route (to, from) {
      this.checkState()
    }
  },
  methods: {
    checkState () {
      if (this.$route.query.path) this.currentFolder = this.$route.query.path
      else this.currentFolder = ''
      this.$emit('hide', false)
      this.setFolder()
    },
    setFolder () {
      if (this.$route.params.file) {
        let slash = '/'
        let currentPath = this.currentFolder
        if (this.currentFolder === '' || this.currentFolder === 'Overige') {
          slash = ''
          currentPath = ''
        }
        if (this.$store.getters.files.includes(currentPath + slash + this.$route.params.file)) this.file = currentPath + '/' + this.$route.params.file
        else {
          this.file = null
          this.pdf = null
        }
      } else {
        this.file = null
        this.pdf = null
      }
      const currentDirectories = []
      const currentFiles = []
      this.$store.getters.directories.forEach(directory => {
        if (this.currentFolder === '') {
          if (directory.split('/').length - 1 === 0) currentDirectories.push(directory)
        } else {
          if (directory.split('/').length - 1 === this.currentFolder.split('/').length && directory.startsWith(this.currentFolder + '/')) currentDirectories.push(directory)
        }
      })
      this.$store.getters.files.forEach(file => {
        if (this.currentFolder === '' || this.currentFolder === 'Overige') {
          if (file.split('/').length - 1 === 0) currentFiles.push(file)
        } else {
          if (file.split('/').length - 1 === this.currentFolder.split('/').length && file.startsWith(this.currentFolder + '/')) currentFiles.push(file)
        }
      })
      this.directories = currentDirectories
      this.files = currentFiles
    },
    searchFolder () {
      if (this.file) {
        this.close()
      }
      if (this.search.trim() === '') this.setFolder()
      else {
        const currentFiles = []
        this.$store.getters.files.forEach(file => {
          if (this.currentFolder === '') {
            if (file.split('/')[file.split('/').length - 1].toLowerCase().includes(this.search.toLowerCase())) currentFiles.push(file)
          } else {
            if (file.split('/')[file.split('/').length - 1].toLowerCase().includes(this.search.toLowerCase()) && file.startsWith(this.currentFolder + '/')) currentFiles.push(file)
          }
        })
        this.directories = []
        this.files = currentFiles
      }
    },
    changeFolder (data) {
      if (data === '') this.$router.push({ path: '/home' }).catch(() => {})
      else this.$router.push({ path: '/home', query: { path: data } }).catch(() => {})
      this.search = ''
      this.currentFolder = data
      this.setFolder()
    },
    newFolder (data) {
      if (this.currentFolder === '') this.currentFolder += data
      else this.currentFolder += '/' + data
      this.$router.push({ path: '/home', query: { path: this.currentFolder } }).catch(() => {})
      this.setFolder()
    },
    nextFolderItems (data) {
      let count = 0
      this.$store.getters.directories.forEach(directory => {
        if (directory.split('/').length === data.split('/').length + 1 && directory.startsWith(data + '/')) count = count + 1
      })
      this.$store.getters.files.forEach(file => {
        if (file.split('/').length === data.split('/').length + 1 && file.startsWith(data + '/')) count = count + 1
      })
      return count
    },
    extension (data) {
      let file = 'random'
      const extension = data.split('.')[data.split('.').length - 1].toLowerCase()
      const images = require.context('../assets/images', false, /\.svg$/)
      const imageExtensions = ['jpeg', 'jpg', 'png', 'gif', 'tiff', 'psd', 'ai']
      if (extension === 'pdf') file = 'pdf'
      if (extension === 'xlsx' || extension === 'xls') file = 'excel'
      if (extension === 'docx' || extension === 'doc') file = 'doc'
      if (extension === 'pptx' || extension === 'ppt') file = 'ppt'
      if (imageExtensions.includes(extension)) file = 'image'
      return images('./' + file + '.svg')
    },
    refresh () {
      this.$store
        .dispatch('getFiles')
        .then(() => this.setFolder())
        .catch(() => this.setError('Er is iets misgegaan'))
    },
    getUrl () {
      return window.location.href
    },
    copyLink () {
      navigator.clipboard.writeText(window.location.href)
      this.copied = true
      const data = this
      setTimeout(function () {
        data.copied = false
      }, 1000)
    },
    async downloadFile (data) {
      await FileService.downloadFile(data).then((response) => {
        const extension = data.split('.')[data.split('.').length - 1].toLowerCase()
        if (extension === 'pdf') {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
          this.pdf = url
          this.$emit('hide', true)
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', data.split('/')[data.split('/').length - 1])
          document.body.appendChild(link)
          link.click()
          link.remove()
        }
      })
    },
    async download (data) {
      const url = this.pdf
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', data.split('/')[data.split('/').length - 1])
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    close () {
      this.file = null
      this.pdf = null
      this.$router.push({ path: '/home', query: { path: this.currentFolder } }).catch(() => {})
    },
    closePdf () {
      this.pdf = null
      this.$emit('hide', false)
    },
    setError (data) {
      this.error = data
    }
  }
}
</script>
